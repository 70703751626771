<template>
    <div>
        <el-dialog title="充值" :visible.sync="editor.dialogFormVisible" >
            <el-form :model="editor"  :rules="rules">
                <el-form-item label="账户充值余额：" :label-width="formLabelWidth">{{editor.shiFuYuE}}</el-form-item>
                <el-form-item label="充值扣款" :label-width="formLabelWidth" prop="shiFuKouKuan">
                    <el-input v-model.number="editor.shiFuKouKuan" autocomplete="off">
                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>
                </el-form-item>
                <el-form-item label="账户赠送余额：">{{editor.zengSongYuE}}</el-form-item>
                <el-form-item label="赠送扣款" :label-width="formLabelWidth" prop="zengSongKouKuan">
                    <el-input v-model.number="editor.zengSongKouKuan" autocomplete="off">
                      <span slot="suffix" >
                        {{ '元' }}
                      </span>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editor.dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="kouKuan">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import {DICT_TYPE_BDCJ, DICT_TYPE_CHONG_ZHI_WAY} from "@/util/constant";
    import {ruleBuilder, isPositiveEightTwo, isPositiveFloatNM, isDigits} from "@/util/validate";

    export default {
        name: "KouKuan",
        mixins: [],
        data() {
            let {required, digits, positiveEightTwo}=ruleBuilder;
            return {
                // editor: {
                //     shiFuYuE:"",
                //     zengSongYuE:"",
                //     shiFuKouKuan: 0,
                //     zengSongKouKuan: 0,
                //     id:'',
                //     dialogFormVisible: false,
                // },
                formLabelWidth: '120px',
                rules:{
                    shiFuKouKuan:[positiveEightTwo()],
                    zengSongKouKuan:[positiveEightTwo()],
                },
            };
        },
        props: {
            editor: {
                type: Object,
                required: true,
            }
        },
        methods: {
            kouKuan(){
                console.log("进来了")
                if (this.editor.shiFuKouKuan < 0 || this.editor.zengSongKouKuan < 0 ){
                    this.$message.error("扣款金额不能小于0")
                    return false
                }
                if (this.editor.shiFuKouKuan + this.editor.zengSongKouKuan === 0 ){
                    this.$message.error("扣款金额不能为0")
                    return false
                }
                if (this.editor.shiFuKouKuan <= this.editor.shiFuYuE  && this.editor.zengSongKouKuan <= this.editor.zengSongYuE){
                    this.editor.dialogFormVisible = false;
                    console.log("this.editor:", this.editor)
                    let data=this.editor

                    console.log("data:", data)
                    hyxxservice.yuEKouKuan(data).then(res=>{
                        // console.log(res)
                        if (res.code===200){
                            this.$emit('success')
                            this.$message.success("扣款成功")
                            this.editor.shiFuKouKuan=0;
                            this.editor.zengSongKouKuan=0;
                        }else {
                            this.$message.warning("扣款成功，请检查用户状态是否正常")
                        }
                    })
                }else {
                    this.$message.error("扣款金额不能大于账户余额，并且检查格式是否正确")
                    return false
                }


            },
        }
    };
</script>

<style lang="scss" scoped>

</style>