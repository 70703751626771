<template>
    <div>
        <!-- 列表区域 -->
        <el-dialog title="收支明细记录" :visible.sync="visible" >
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
<!--            <el-table-column width="180" prop="kaHao" label="卡号" />-->
            <el-table-column width="180" prop="shouJiHao" label="手机号" />
            <el-table-column width="180" prop="xingMing" label="姓名" />
            <el-table-column width="180" prop="bianDongJinE" label="变动金额" />
            <el-table-column width="180" prop="bianDongLX" label="变动类型" >
                <x-dict-show slot-scope="{row}" :code="row.bianDongLX" dictType="T_BianDong_LX" />
            </el-table-column>
            <el-table-column width="180" prop="bianDongChangJing" label="变动场景" >
                <x-dict-show slot-scope="{row}" :code="row.bianDongChangJing" dictType="T_BianDong_CJ" />
            </el-table-column>
            <el-table-column width="180" prop="bianDongSJ" label="变动时间" />
<!--            <el-table-column width="180" prop="caoZuoRen" label="操作人" />-->
<!--            <el-table-column width="180" prop="bianDongMenDian" label="变动门店" />-->
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
        </el-dialog>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/YuEJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/hygl/yejl/YuEJiLuDetail";

    export default {
        name: "YuEJiLuQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = service.query;
            return {
                search: {
                    kaHao: '',
                    shouJiHao: '',
                    xingMing: '',
                    bianDongSJBegin: '',
                    bianDongSJEnd: '',
                },
                visible:false,
            }
        },
        methods: {
            showJiLu(sjh){
                this.search.shouJiHao = sjh
                this.visible = true
                this.handleSearch()
            }
        }
    }
</script>

<style scoped>
</style>