<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="卡号" >
                <el-input v-model="search.kaHao" size="small" />
            </x-search-item>
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <x-search-item label="手机号" >
                <el-input v-model="search.shouJiHao" size="small" />
            </x-search-item>
            <x-search-item label="会员类型" >
<!--                <el-select v-model="search.huiYuanLeiXing" placeholder="请选择" size="small">-->
<!--                    <el-option v-for="item in hylxList" :label="item.name" :value="item.name">-->
<!--                    </el-option>-->
<!--                </el-select>-->
                <x-selector-one  v-model="search.huiYuanLeiXing" dictType="T_HUI_YUAN_LX"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>

<!--                    <el-button type="primary" size="mini" @click="exportExcel" style="background-color: #f0f0f0;color: black;border: #8f8f9d 1px solid;margin-left: 10px;">导出会员信息>></el-button>-->
<!--                    <label style="margin-left: 30px;font-weight: bold">导入会员信息：</label>-->
<!--                    <div id="uploadFile" style="line-height: 35px">-->
<!--                        <input type="file" ref="upload"  accept=".xls,.xlsx" >-->
<!--                    </div>-->
<!--                    <el-button type="primary" size="mini">-->
<!--                        <a  href="./static/hygl/会员信息.xlsx" style="color: #fff" download="会员信息.xlsx">下载导入模板</a>-->
<!--                    </el-button>-->


<!--                <el-button type="primary" size="mini">-->
<!--                    <label style="font-weight: bold" @click="daoRuShow">导入会员信息</label>-->
<!--                </el-button>-->
            </div>
        </div>

        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="kaHao" label="卡号" />
            <el-table-column width="180" prop="xingMing" label="姓名" />
            <el-table-column width="180" prop="shouJiHao" label="手机号" />
            <el-table-column width="180" prop="huiYuanLeiXing" label="会员类型" >
                <x-dict-show slot-scope="{row}" :code="row.huiYuanLeiXing" dictType="T_HUI_YUAN_LX" />
            </el-table-column>
<!--            <el-table-column width="180" prop="plusZheKou" label="plus折扣" />-->
<!--            <el-table-column width="180" prop="huiYuanDJ" label="会员等级" />-->
<!--            <el-table-column width="180" prop="leiJiJiFen" label="累计积分" />-->
<!--            <el-table-column width="180" prop="shengYuJiFen" label="剩余积分" />-->
            <el-table-column width="180" prop="shengYuJinE" label="剩余金额" />
            <el-table-column width="180" prop="chongZhiJinE" label="充值金额" />
            <el-table-column width="180" prop="zengSongJinE" label="赠送金额" />
<!--            <el-table-column width="180" prop="huiYuanBiaoQian" label="会员标签" />-->
<!--            <el-table-column width="180" prop="kaZhuangTai" label="卡状态" >-->
<!--                <x-dict-show slot-scope="{row}" :code="row.kaZhuangTai" dictType="T_KA_ZT" />-->
<!--            </el-table-column>-->
<!--            <el-table-column width="180" prop="kaiKaSJ" label="开卡时间" />-->
<!--            <el-table-column width="180" prop="shiFuZhuXiao" label="是否注销" >-->
<!--                <x-dict-show slot-scope="{row}" :code="row.shiFuZhuXiao" dictType="T_YES_OR_NO" />-->
<!--            </el-table-column>-->
<!--            <el-table-column width="180" prop="suoShuMenDian" label="所属门店" />-->
<!--            <el-table-column width="180" prop="caoZuoRen" label="操作人" />-->
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
<!--                    <el-dropdown-item>-->
<!--                        <el-button type="success" size="mini" round @click="xiangQingShow(row.id)">详细信息<i class="el-icon-tickets el-icon&#45;&#45;right"></i></el-button>-->
<!--                    </el-dropdown-item>-->

                    <el-button   type="primary" size="mini" round  @click="kouKuan(row)">扣款</el-button>

                    <el-button type="success" size="mini" round @click="jiLuDetail(row.shouJiHao)">查看</el-button>



                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <kou-kuan ref="kouKuan" :editor="kouKuanEditor" @success="refresh"/>
        <yu-e-ji-lu ref="yuEJiLu" />


    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanXX";
    import XTableBase from "@/components/x/XTableBase";
    import {ruleBuilder } from "@/util/validate";
    import KouKuan from "@/view/hygl/czgl/KouKuan";
    import YuEJiLu from "@/view/hygl/yejl/YuEJiLuQuery";

    export default {
        name: "HuiYuanYuE",
        mixins: [XTableBase],
        components: {KouKuan, YuEJiLu},
        data() {
            this.refreshService = service.list;
            this.service = service;
            let {required} = ruleBuilder;
            return {
                search: {
                    kaHao: '',
                    xingMing: '',
                    shouJiHao: '',
                    huiYuanLeiXing: '',
                    kaZhuangTai: '',
                    kaiKaSJBegin: '',
                    kaiKaSJEnd: '',
                    suoShuMenDian: '',
                },
                kouKuanEditor: {
                    shiFuYuE: "",
                    zengSongYuE: "",
                    shiFuKouKuan: 0,
                    zengSongKouKuan: 0,
                    id: '',
                    dialogFormVisible: false,
                },
                formLabelWidth: '120px',
                menDianList: []
            }
        },
        mounted() {

        },
        methods: {
            kouKuan(row) {
                console.log("this.kouKuanEditor.dialogFormVisible:",this.kouKuanEditor.dialogFormVisible)
                this.kouKuanEditor.shiFuYuE=row.chongZhiJinE
                this.kouKuanEditor.zengSongYuE=row.zengSongJinE
                this.kouKuanEditor.id=row.id
                this.kouKuanEditor.dialogFormVisible=true
            },
            jiLuDetail(sjh) {
                this.$refs.yuEJiLu.showJiLu(sjh)
            },

        }

    }
</script>

<style scoped>
</style>
